<template>
    <div class="container" v-bind:class="{ 'full-screen': fullScreen }">
        <transition name="backdrop-transition" :duration="5000">
            <div v-show="isMounted" class="backdrop" @click="dismiss">
                <div v-if="mandatoryAuthHeader" class="close-btn-message">
                    <h4 class="H2 white-color">To continue this action you must be a member of Crowdpac.</h4>
                    <h4 class="H2 white-color">Please sign up or Log in</h4>
                    <span @click="handleCloseButton" class="close-btn-container"><i class="fal fa-times fa-2x neutral3-color"></i></span>
                </div>
            </div>
        </transition>

        <transition name="dialog-transition">
            <div v-show="isMounted" class="center-finder">
                <span class="focus-trap" tabindex="0" @focus="focusLast"></span>

                <div ref="outside" :class="{outside: true, 'without-scrolling': withoutScrolling, 'auth-header-margin': mandatoryAuthHeader}" tabindex="0" role="dialog" @keyup.esc="dismiss">
                    <div class="inside">
                        <slot></slot>
                    </div>
                </div>

                <span class="focus-trap" tabindex="0" @focus="focusFirst"></span>
            </div>
        </transition>
    </div>
</template>

<script>
const FOCUSABLES_SELECTOR = [
    'a[href]',
    'area',
    'button',
    'input',
    'select',
    'textarea',
    '[tabindex]'
].map(selector => `${selector}:not([tabindex="-1"])`).join(',');

export default {
    data() {
        return {
            isMounted: false,
            activeElementAtCreation: null,
        };
    },
    props : {
        fullScreen : {type: Boolean, default : false},
        withoutScrolling: {type: Boolean, default: false},
        mandatoryAuthHeader: {type: Boolean, default: false},
        handleCloseButton: {type: Function, default: function(){}},
    },
    methods: {
        incrementOpenDialogCount() {
            const originalRootWidth = document.documentElement.offsetWidth;

            const dialogsThatWereOpen = parseFloat(document.documentElement.dataset.dialogsOpen) || 0;

            document.documentElement.dataset.dialogsOpen = dialogsThatWereOpen + 1;

            if (dialogsThatWereOpen === 0) {
                const scrollbarWidth = document.documentElement.offsetWidth - originalRootWidth;
                document.documentElement.style.setProperty('--scrollbar-width', `${scrollbarWidth}px`);
            }
        },

        decrementOpenDialogCount() {
            const dialogsOpen = parseFloat(document.documentElement.dataset.dialogsOpen);

            if (dialogsOpen === 1) {
                delete document.documentElement.dataset.dialogsOpen;
            } else {
                document.documentElement.dataset.dialogsOpen = dialogsOpen - 1;
            }
        },

        dismiss() {
            this.$emit('dismiss');
        },

        focusLast() {
            const focusables = this.$refs.outside.querySelectorAll(FOCUSABLES_SELECTOR);
            focusables[focusables.length - 1].focus();
        },

        focusFirst() {
            this.$refs.outside.focus();
        },
    },

    created() {
        if (typeof document !== 'undefined') {
            this.activeElementAtCreation = document.activeElement;
        }
    },

    async mounted() {
        this.isMounted = true;
        await this.$nextTick();

        this.incrementOpenDialogCount();

        const descendantWithAutofocus = this.$refs.outside.querySelector('[autofocus]');
        const elementToFocusOnMount = descendantWithAutofocus || this.$refs.outside;
        elementToFocusOnMount.focus();
    },

    beforeDestroy() {
        this.isMounted = false;

        this.decrementOpenDialogCount();

        if (this.activeElementAtCreation) {
            this.activeElementAtCreation.focus();
        }
    },
};
</script>

<style>
    :root {
        --dialog-backdrop: rgba(0, 0, 0, 0.7);
        --dialog-background: white;
    }

    :root[data-dialogs-open] {
        overflow: hidden;
        padding-right: var(--scrollbar-width, 0);
    }
</style>

<style scoped>
.container {
    color: black;
    position: relative;
    z-index: 9999; /* Get above the site header. */
}

.backdrop-transition-enter-active {
    transition: opacity 0.2s;
}

.backdrop-transition-enter,
.backdrop-transition-leave-to {
    opacity: 0;
}

.backdrop-transition-enter-to,
.backdrop-transition-leave {
    opacity: 1;
}

.backdrop {
    background: var(--dialog-backdrop);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
}

.dialog-transition-enter-active {
    transition: opacity 0.1s, transform 0.1s;
    transform-origin: left top;
}

.dialog-transition-enter,
.dialog-transition-leave-to {
    opacity: 0;
    transform: scale(0.8);
}

.dialog-transition-enter-to,
.dialog-transition-leave {
    opacity: 1;
    transform: scale(1);
}

.center-finder {
    height: 100vh;
    left: 50%;
    pointer-events: none;
    position: fixed;
    top: 50%;
    width: 100vw;
}

.outside {
    background: var(--dialog-background);
    outline: none;
    display: inline-block;
    max-height: 90vh;
    max-width: 90vw;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    pointer-events: initial;
    position: relative;
    transform: translate(-50%, -50%);
    border-radius: 4px;
}

.without-scrolling {
    overflow: hidden;
}

.close-btn-message {
    text-align: center;
    padding-top: 20px;
}

.close-btn-container {
    display: flex;
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: var(--white);
    border-radius: 50%;
    width: 48px;
    height: 48px;
    border: 1px solid var(--neutral3);
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

div.container.full-screen .outside{
  width:100%;
  height:100%;
  max-width:100%;
  max-height:100%;
}

.focus-trap {
    position: absolute;
}

.auth-header-margin {
    margin-top: 20px;
}
</style>